import React, { Component } from "react";
import Identification from "./Identification";
import { getEkataBucket } from '../helpers/ekata_helper';
import { FLAGGED_ROUTING_NUMBERS } from '../helpers/fraud_helper';
import { formatYearFirstDashDate } from '../helpers/date_helper';

class UserIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0
    };
  }

  render() {
    const analytics = this.props.user_id_card.analytics;
    let color = "id_yellow";
    switch (analytics.aggregate_check) {
      case -1:
        color = "id_red";
        break;
      case 0:
        color = "id_yellow";
        break;
      case 1:
        color = "id_green";
        break;
      default:
        color = "id_yellow";
    }

    let exifData = this.props.user_id_card.has_images_with_exif;
    let exifDataTimestamp = null;
    if (exifData && exifData['date_time']) {
      let imgTimestamp = new Date(exifData['date_time']);
      exifDataTimestamp = `${imgTimestamp.getFullYear()}-${imgTimestamp.getMonth()+1}-${imgTimestamp.getDate()}`;
    }

    return (
      <div className="identification">
        <div className={"row_container " + color}>
          {Object.values(this.props.user_id_card.images).map(
            identification => (
              <Identification
                key={identification.ident_id}
                identification={identification}
              />
            )
          )}
        </div>
        <div className={"info_box "}>
          <p>User name: {this.props.user_id_card.user_name}</p>
          <p>User Id: {this.props.user_id_card.user_id}</p>
          <p>Birthday: {formatYearFirstDashDate(this.props.user_id_card.birthday)}</p>
          <p>
            Drivers License Number:{" "}
            {this.props.user_id_card.drivers_license
              ? this.props.user_id_card.drivers_license
              : "Unavailable"}
          </p>
          <p>Has EXIF Data? { exifData ? 'Yes' : 'No (Possibly AI generated)' }</p>
          <p><b>{ exifDataTimestamp ? `Image taken ${exifDataTimestamp}` : ''}</b></p>
          <p>
            Routing Number:{" "}
            <span className={FLAGGED_ROUTING_NUMBERS.includes(this.props.user_id_card.routing) ? 'flagged_routing_number' : ''}>
              {this.props.user_id_card.routing}
              {FLAGGED_ROUTING_NUMBERS.includes(this.props.user_id_card.routing) ? ' (Flagged)' : ''}
            </span>
          </p>
          <p>
            Ekata Fraud Score: {" "}
            <span className={getEkataBucket(this.props.user_id_card.ekata_fraud_score).className}>
              {this.props.user_id_card.ekata_fraud_score} 
              {" "}({getEkataBucket(this.props.user_id_card.ekata_fraud_score).label})
            </span>
          </p>

          {Object.keys(analytics)
            .sort()
            .map((key, i) => (
              <p key={key}>
                {key && key !== "job_id" ? (
                  analytics[key] !== undefined && analytics[key] ? (
                    <span className={analytics[key] === false ? "id_red" : ""}>
                      {key.toString() + ": " + analytics[key].toString()}
                    </span>
                  ) : (
                    <span className="id_red">{key.toString() + ": false"}</span>
                  )
                ) : (
                  ""
                )}
              </p>
            ))}
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_id_card.user_id,
                "approve"
              );
            }}
          >
            Approve
          </button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_id_card.user_id,
                "request"
              );
            }}
          >
            Re-Request
          </button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_id_card.user_id,
                "cancel"
              );
            }}
          >
            Cancel Loan
          </button>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_id_card.user_id,
                "selfie"
              );
            }}
          >
            Selfie Cancel
          </button>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_id_card.user_id,
                "manual_fraud_hold"
              );
            }}
          >
            Manual Fraud Hold
          </button>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default UserIdentification;
