export const EKATA_SCORE_BUCKETS = Object.freeze({
  LOW: {
    min: 0,
    max: 256,
    label: "Low risk",
    className: "ekata-low-risk"
  },
  MODERATE: {
    min: 257,
    max: 440,
    label: "Moderate risk",
    className: "ekata-moderate-risk"
  },
  HIGH: {
    min: 441,
    max: Infinity,
    label: "High risk",
    className: "ekata-high-risk"
  }
});

export const getEkataBucket = (score) => {
  if (score <= EKATA_SCORE_BUCKETS.LOW.max) return EKATA_SCORE_BUCKETS.LOW;
  if (score <= EKATA_SCORE_BUCKETS.MODERATE.max) return EKATA_SCORE_BUCKETS.MODERATE;
  return EKATA_SCORE_BUCKETS.HIGH;
}; 